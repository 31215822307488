$colors: (
    "white": #ffffff,
    "black": #000000,
    "light-gray": #b4b4b4,
    "medium-gray": #7a7a7a,
    /* CLASSIC */
    "steel-blue": #4e6996,
    "dark-sea-green": #2b8c4c,
    "dark-gray": #343A40,
    "dark-violet": #7F6289,
    "dark-tan": #9C8259,
    /* PRIMARY */
    "primary-red": #EB5656,
    //"primary-yellow": #F3D74C,
    "primary-yellow": #f0db72,
    "primary-blue": #6ABBF1,
    /* SECONDARY */
    "secondary-orange": #e29043,
    "secondary-green": #4cb241,
    "secondary-purple": #914af4,
    /* PURPLES */
    "blue-violet": #501C80,
    "purple": #6525A5,
    "dark-orchid": #36164f,
    "lavender": #B56AEE,
    /* COFFEE */
    "dark-coffee": #7F5539,
    "brown-sugar": #9C6644,
    "cafe-au-lait": #B08968,
    "burlywood": #DDB892,
    "black-coffee": #37281f,
    /* warm harvest */
    "rust-orange": #e07a5f,
    /* underwater */
    "sea-lapis-lazuli": #1e6091
);

/* BACKGROUND COLORS */

@each $name, $color in $colors {
    .bg-#{$name} {
        background-color: $color;
    }
    
    .text-#{$name} {
        color: $color;
    }

    .fill-#{$name} {
        fill: $color;
    }
}

.bg-white-10 {
    background-color: rgba(255, 255, 255, 0.1);
}

.bg-white-50 {
    background-color: rgba(255, 255, 255, 0.5);
}

.bg-black-10 {
    background-color: rgba(0, 0, 0, 0.1);
}

.bg-black-50 {
    background-color: rgba(0, 0, 0, 0.5);
}

.bg-secondary-10 {
    background-color: rgba(108, 117, 125, 0.1);
}

.bg-secondary-50 {
    background-color: rgba(108, 117, 125, 0.5);
}

.hover-bg-secondary-10:hover {
    background-color: rgba(108, 117, 125, 0.1);
}

.border-3 {
    border-width:6px;
}

.greyed-out {
    color:rgb(86, 86, 86)
}

.greyed-out:hover {
    color:rgb(140, 140, 140)
}