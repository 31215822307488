@use "sass:math";

.flex {
    display: flex;
}
.column {
    display: flex;
    flex-direction: column;
}



.column-centered {
    @extend .column;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-between {
    @extend .row;
    justify-content: space-between;
}

.row-center {
    @extend .row;
    justify-content: center;
}

.row-between-centered {
    @extend .row-between;
    align-items: center;
}

.align-center {
    align-items: center;
}

.align-baseline {
    align-items: baseline;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-end {
    justify-content: flex-end;
}


.container {
    width: 100%;
}

.mx-auto {
    margin: 0px auto;
}

.my-auto {
    margin: auto 0px;
}

$widths: (25, 50, 75, 85, 100);

@each $width in $widths {
    .w-#{$width} {
        width: math.percentage(math.div($width, 100)) !important;
    }

    @media screen and (min-width: 800px) {
        .lg-w-#{$width} {
            width: math.percentage(math.div($width, 100)) !important;
        }
    }
}

.w-full {
    width: 100% !important;
}

.w-max-content {
    width: max-content;
}
 
.min-h-75vh {
    min-height: 75vh !important;
}

$max-widths: (500, 750, 900);
@each $width in $max-widths {
    .max-w-#{$width} {
        max-width: #{$width}px;
    }
}

.w-100vw {
    width: 100vw !important;
}

.wrap {
    flex-wrap: wrap;
}

.border-box {
    box-sizing: border-box;
}

/*
 COLUMNS
*/

@for $i from 1 through 12 {
    .col-#{$i} {
        @extend .column;
        -ms-flex: 0 0 math.percentage(math.div($i, 12));
        flex: 0 0 math.percentage(math.div($i, 12));
        max-width: math.percentage(math.div($i, 12));
    }

    @media (min-width:600px) and (max-width:992px) {
        .col-md-#{$i} {
            -ms-flex: 0 0 math.percentage(math.div($i, 12)) !important;
            flex: 0 0 math.percentage(math.div($i, 12)) !important;
            max-width: math.percentage(math.div($i, 12)) !important;
        }
    }
    
    @media (min-width:992px) {
        .col-lg-#{$i} {
            -ms-flex: 0 0 math.percentage(math.div($i, 12)) !important;
            flex: 0 0 math.percentage(math.div($i, 12)) !important;
            max-width: math.percentage(math.div($i, 12)) !important;
        }
    }
}

@media (min-width:992px) {
    .col-lg {
        display: flex;
        flex-direction: column;
        -ms-flex: 0 0 1 !important;
        flex: 0 0 1 !important;
        max-width: 1 !important;

    }
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.sticky {
    position: sticky;
    top: 0px;
}

.fixed {
    position: fixed;
}

.top-0 {
    top: 0px;
}

.rel-top-left {
    @extend .relative;
    top: 10px;
    left: 10px;
}

.absolute-top-left {
    @extend .absolute;
    top: 15px;
    left: 15px;
}

.full-page {
    width: 100vw;
    min-height: 100vh;
}

.fill {
    width: 100%;
    height: 100%;
}